<template>
    <div class="insur">
        <div class="content-p">
            <div class="flex po-r">
                <img
                    class="dec-icon1 po-a"
                    src="@/assets/img/business/dec-icon4.png"
                    width="156px"
                    height="154px"
                />

                <div class="item1 po-a">
                    <div class="item-title">
                        <span>面向开发商</span>
                    </div>
                    <div class="item-text">
                        <p>
                            土地前融，开发贷，供应链金融及不良资产处置基金，提供全周期服务
                        </p>
                    </div>
                </div>
                <img
                    class="item1-pic"
                    src="@/assets/img/business/finan-item1.png"
                    width="1200px"
                    height="535px"
                />
            </div>
            <div class="flex mar-t-80 po-r mar-t-115">
                <img
                    class="dec-icon3 po-a"
                    src="@/assets/img/business/dec-icon3.png"
                    width="156px"
                    height="154px"
                />

                <div class="item2">
                    <div class="item-title">
                        <span>面向消费者</span>
                    </div>
                    <div class="item-text">
                        <p>
                            提供按揭服务，装修贷，租金分期等服务，帮助消费者解决短期资金困难
                        </p>
                    </div>
                </div>
                <img
                    src="@/assets/img/business/finan-item2.png"
                    width="703px"
                    height="382px"
                />
            </div>
            <div class="flex po-r mar-t-128">
                <img
                    src="@/assets/img/business/finan-item3.png"
                    width="716px"
                    height="444px"
                />
                <div class="item3">
                    <div class="item-title">
                        <span>面向合伙人</span>
                    </div>
                    <div class="item-text">
                        <p>
                            通过佣金保理秒结算，经营贷等服务，支持合伙人业务开展
                        </p>
                    </div>
                </div>
            </div>
            <div class="flex po-r mar-t-120 mar-b-159">
                <img
                    class="dec-icon4 po-a"
                    src="@/assets/img/business/dec-icon3.png"
                    width="156px"
                    height="154px"
                />
                <div class="item4">
                    <div class="item-title">
                        <span>面向金融机构</span>
                    </div>
                    <div class="item-text">
                        <p>提供优质金融资产，全链条数据及完备的风控体系</p>
                    </div>
                </div>
                <img
                    src="@/assets/img/business/finan-item4.png"
                    width="676px"
                    height="421px"
                />
            </div>
        </div>
    </div>
</template>
<script>
 
export default {
  data(){
      return{
          
         
      }
  },
  components: {
  }
}
</script>
<style lang="scss" scoped>
.mar-t-115 {
    margin-top: 115px;
}
.mar-t-128 {
    margin-top: 128px;
}
.mar-t-120 {
    margin-top: 120px;
}
.mar-b-159 {
    margin-bottom: 159px;
}

.item1-pic {
    right: 0;
}
.item1 {
    padding-top: 40px;
    padding-left: 88px;
    .item-text {
        width: 430px;
    }
}
.item2 {
    margin-top: 60px;
    margin-right: 67px;
}
.item3 {
    margin-left: 54px;
    padding-top: 60px;
}
.item4 {
    margin-right: 94px;
    margin-top: 163px;
}
.dec-icon1 {
    left: -60px;
    bottom: 0;
}
.dec-icon2 {
    bottom: -33px;
    right: -35px;
}
.dec-icon3 {
    bottom: -5px;
    right: -33px;
}
.dec-icon4 {
    bottom: -50px;
    right: -89px;
    z-index: -1;
}
</style>
