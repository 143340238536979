<template>
    <div class="insur">
        <div class="content-p">
            <div class="flex po-r">
                <img
                    class="dec-icon1 po-a"
                    src="@/assets/img/business/dec-icon2.png"
                    width="56px"
                    height="154px"
                />
                <img
                    class="item1-pic"
                    src="@/assets/img/business/insur-item1.png"
                    width="1200px"
                    height="517px"
                />
                <div class="item1 po-a">
                    <div class="item-title">
                        <span>行业赋能 提升业务效率</span>
                    </div>
                    <div class="item-text">
                        <p>
                            联合业内保险公司，为保险客户按业务场景差异化定制专属产品，并线上对接核保，理赔，投保，支付通道等多类系统，极大提升业务效率
                        </p>
                    </div>
                </div>
            </div>
            <div class="flex po-r mar-t-123">
                 <div class="item2 fl1">
                    <div class="item-title">
                        <span
                            >中台赋能，构建一站式全流程解决方案保险科技服务平台</span
                        >
                    </div>
                    <div class="item-text">
                        <p>
                            投保中心，续保管理，佣金核算，展业平台功能齐备，强力支撑销售业务开展
                        </p>
                        <p>
                            基于AI&大数据算法精准分析客户需求，通过智能投放高效支持广告运营和营销活动开展，推动销售线索量质齐升
                        </p>
                        <p>接入多个第三方流量平台，带来海量精准客户群体</p>
                    </div>
                </div>
                <div class="po-r  item2-pic-box">
                    <img
                        class="po-a"
                        src="@/assets/img/business/insur-item2.png"
                        width="343px"
                        height="420px"
                    />
                    <img
                        class="po-a"
                        src="@/assets/img/business/insur-item2-1.png"
                        width="274px"
                        height="380px"
                    />
                </div>
            </div>
            <div class="flex po-r mar-t-109">
                <!-- <img
                    class="dec-icon4 po-a"
                    src="@/assets/img/business/dec-icon3.png"
                    width="156px"
                    height="154px"
                /> -->

                <div class="item3-pic-box po-r fl1">
                    <img
                        class="po-a"
                        src="@/assets/img/business/insur-item3.png"
                        width="587px"
                        height="391px"
                    />
                    <img
                        class="po-a"
                        src="@/assets/img/business/insur-item3-1.png"
                        width="541px"
                        height="360px"
                    />
                </div>
                <div class="item3">
                    <div class="item-title">
                        <span>合伙人赋能，促进业务开展</span>
                    </div>
                    <div class="item-text">
                        <p>
                            为保险代理人，平台合伙人等提供作业平台和保险产品资源
                        </p>
                        <p>财产险，项目险，车险等多样产品服务支撑</p>
                        <p>多渠道多形式分发内容，触达更多高净值资产人群</p>
                        <p>
                            结合房、车、养等业务场景和特定人群，有针对性的提供差异化的专属产品
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
 
export default {
  data(){
      return{
          
         
      }
  },
  components: {
  }
}
</script>
<style lang="scss" scoped>
.mar-t-123 {
    margin-top: 123px;
}
.mar-t-109 {
    margin-top: 109px;
    margin-bottom: 139px;
}

.item1-pic {
    right: 0;
}
.item1 {
    z-index: 1;
    margin-right: 48px;
    padding-top: 80px;
    right: 0;
    .item-text {
        width: 330px;
    }
}
.item2 {
    margin-top: 60px;
    margin-right: 15px;
}
.item2-pic-box {
    height: 420px;
    width: 343px;
    > img {
        &:nth-child(1) {
            top: 0;
            right: 0;
        }
        &:nth-child(2) {
            bottom: -22px;
            right: -33px;
            z-index: -1;
        }
    }
}
.item3-pic-box {
    height: 391px;
    > img {
        &:nth-child(1) {
            top: 0;
            left: 0;
        }
        &:nth-child(2) {
            bottom: -31px;
            left: -52px;
            z-index: -1;
        }
    }
}
.item3 {
    margin-left: 553px;
    padding-top: 91px;
}
.dec-icon1 {
    top: -17px;
    left: -40px;
    z-index: -1;
}
.dec-icon2 {
    bottom: -33px;
    right: -35px;
}
.dec-icon3 {
    bottom: 72px;
    left: -40px;
}
.dec-icon4 {
    bottom: -5px;
    right: -33px;
    z-index: -1;
}
</style>
