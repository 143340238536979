 <!-- 业务 -->
<template>
    <div class="Business po-r">
        <div class="content">
            <div class="title-box">
                <h1>数十万亿非标大宗市场</h1>
                <h1>产业数字化再造</h1>
                <p>全链路、全角色、全周期的交易及服务生态</p>
                <p>打造中国及东南亚 航母级非标大宗商品交易及服务平台</p>
            </div>
        </div>
        <div class="banner-bg"></div>
        <div class="content-p po-r">
            <div class="tabs-box">
                <el-tabs class="" v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane
                        :name="item.name"
                        v-for="(item, idx) in tabsList"
                        :key="idx"
                    >
                        <div slot="label" class="label-box">
                            <transition name="fade">
                                <p v-if="activeName !== item.name">
                                    {{ item.title }}
                                </p>
                                <p v-else>{{ item.allTitle }}</p>
                            </transition>
                            <span>{{ item.EN.toUpperCase() }}</span>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>

        <!-- <house v-show="false"></house>
        <health v-show="false"></health>
        <car></car> -->
        <transition name="fade">
            <component :is="activeName"></component>
            <!-- <societyPage v-show="false"></societyPage>
            <schoolPage></schoolPage> -->
        </transition>
    </div>
</template>

<script>
 import {tabs,tabPane} from "element-ui"
 import house from "@/components/business/house"
 import health from "@/components/business/health"
 import car from "@/components/business/car"
 import insur from "@/components/business/insur"
 import finan from "@/components/business/finan"
export default {
  name: 'Business',
  components: {
       elTabs:tabs,
      elTabPane:tabPane,
      house,
      health,
      car,
      insur,
      finan
  },
  data(){
      return {
          activeName:'house',
          tabsList:[
              {allTitle:'好生活·好房',title:"房产",EN:'good house',name:'house'},
              {allTitle:'好生活·好康养',title:"康养 ",EN:'good health',name:'health'},
              {allTitle:'好生活·好汽车',title:"汽车 ",EN:'good car',name:'car'},
              {allTitle:'好生活·好保险',title:"保险 ",EN:'good insurance',name:'insur'},
              {allTitle:'好生活·好金融',title:"金融",EN:'good finance',name:'finan'}, 
          ],
      }
  },
  methods:{
      handleClick(){}
  },
}
</script>
<style lang="scss" scoped>
.banner-bg {
    background: #ecf2fc;
    height: 480px;
}
.banner-box {
    height: 480px;
    max-width: 1920px;
    margin: 0 auto;
    background: url("~@/assets/img/business/banner.jpg") no-repeat right center
        #ecf2fc;
    background-size: contain;
}

.label-box {
    > p {
        height: 15px;
    }
    > span {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
        line-height: 19px;
        font-weight: 400;
        letter-spacing: 0px;
    }
}
.title-box {
    padding-top: 96px;
    width: 1550px;
    position: absolute;
    margin: 0 auto;
    background: url("~@/assets/img/business/banner.jpg") no-repeat 450px 0
        #ecf2fc;
    height: 480px;
    background-size: contain;
    > h1 {
        font-size: 44px;
        font-weight: bold;
        line-height: 56px;
        letter-spacing: 3px;
    }
    > p {
        &:first-of-type {
            margin-top: 21px;
        }
        font-size: 28px;
        line-height: 40px;
        letter-spacing: 2px;
    }
}
.tabs-box {
    background: #ffffff;
    box-shadow: 0px 12px 40px 0px rgba(72, 152, 255, 0.12);
    border-radius: 4px;
    padding: 20px 50px;
    margin-top: -38px;
    padding-bottom: 0;
    margin-bottom: 80px;
}
</style>
<style lang="scss">
.Business {
    .item-title {
        //margin-top: 106px;
        margin-bottom: 32px;
        span {
            &:nth-child(1) {
                font-size: 32px;
                font-weight: bold;
                line-height: 42px;
            }
            &:nth-child(2) {
                font-size: 24px;
                line-height: 36px;
            }
        }
    }
    .item-text {
        > h5 {
            font-size: 26px;
            font-weight: bold;
            line-height: 36px;
            margin-bottom: 16px;
        }
        > p {
            font-size: 18px;
            line-height: 36px;

            & + p {
                margin-top: 6px;
            }
        }

        & + .item-text {
            margin-top: 32px;
        }
    }

    .el-tabs__nav-wrap::after {
        display: none;
    }

    .el-tabs__nav {
        display: flex;
        width: 100%;
        height: 80px;
    }
    .el-tabs__item {
        height: 70px;
        flex: 1;
        width: 220px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        line-height: 65px;
        letter-spacing: 2px;
        color: #6679a1;
        transition: all 0.3s;

        &.is-active {
            color: #fff;
        }
    }
    .el-tabs__active-bar.is-top {
        height: 80px;
        background: url("~@/assets/img/business/tab-bg.png") no-repeat center;
        background-size: contain;
        z-index: -1;
    }
}
</style>
