<template>
    <div class="house content-p mar-b-102">
        <div class="item-title mar-t-100">
            <span>面向消费者</span>
            <span>//提供全线上一站式自助交易平台</span>
        </div>
        <div class="item1">
            <img src="@/assets/img/business/house-item1.jpg" height="340px" />
        </div>
        <div class="item-title mar-t-100">
            <span>面向开放商</span>
            <span>//提供全产业链服务，从选地-买地-策划-销售实现</span>
        </div>
        <div class="flex item2">
            <div class="item2-text-box">
                <div class="item-text">
                    <h5>土地交易</h5>
                    <p>土地一二级市场信息公布</p>
                    <p>
                        土地交易前期申请授权、立项融资、开发贷、供应链金融等全周期服务
                    </p>
                </div>
                <div class="item-text">
                    <h5>精准策划</h5>
                    <p>
                        利用行业、用户、渠道等海量大数据，构建人工智能精准策划算法体系，同时拥有资深策划团队和优质策划合作伙伴，协同提供业内领先的、大数据支持的、专业落地的策划服务
                    </p>
                </div>
                <div class="item-text">
                    <h5>专属电商旗舰店</h5>
                    <p>
                        一键拥有专属电商旗舰店，自助运营，对接海量合伙人与客户，共享一站式自助交易服务
                    </p>
                </div>
                <div class="item-text">
                    <h5>多项增值服务</h5>
                    <p>
                        VR在线售楼处、网红直播、智慧工地、智慧设计、创新锁客组合产品、按揭服务、代办服务
                    </p>
                </div>
            </div>
            <div class="item2-img-box fl1 po-r">
                <img
                    src="@/assets/img/business/house-item2.png"
                    width="396px"
                    height="591px"
                    class="po-a"
                />
                <img
                    src="@/assets/img/business/house-item2-1.png"
                    width="400px"
                    height="676px"
                    class="po-a"
                />
            </div>
        </div>
        <div class="item-title mar-t-112">
            <span>面向合伙人</span>
            <span>//提供一站式生意平台</span>
        </div>
        <div class="content flex">
            <div class="item3-text-box">
                <div class="item-text">
                    <p>
                        一键拥有专属电商旗舰店，自助运营，对接海量合伙人与客户，共享一站式自助交易服务
                    </p>
                </div>
                <div class="item-text">
                    <p>
                        一键拥有专属电商旗舰店，自助运营，对接海量合伙人与客户，共享一站式自助交易服务
                    </p>
                </div>
            </div>
            <div>
                <img
                    src="@/assets/img/business/house-item3.jpg"
                    width="800px"
                    height="438px"
                />
            </div>
        </div>
    </div>
</template>
<script>
 
export default {
  data(){
      return{
       
      }
  },
  components: {
  }
}
</script>
<style lang="scss" scoped>
.mar-t-100{
    margin-top:100px;
}
.mar-t-112{
    margin-top: 112px;
}
.mar-b-102{
    margin-bottom: 102px;
}

.item2 {
    padding-bottom: 50px;
    padding-top: 8px;
}
.item2-text-box {
    width: 744px;
    margin-right: 60px;
    padding-top: 4px;
}
.item2-img-box {
    > img {
        &:nth-child(1) {
            z-index: 1;
            top: 49px;
        }
        &:nth-child(2) {
            top: 0;
            left: 66px;
        }
    }
}
.item3-title{
    margin-bottom: 10px;
}
.item3-text-box {
    width: 372px;
    margin-right: 28px;
    padding-top: 30px;
    .item-text + .item-text {
        margin-top: 38px;
    }
}
</style>
