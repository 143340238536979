<template>
    <div class="car mar-b-100">
        <div class="content-p">
            <div class="flex po-r">
                <img
                    class="dec-icon1 po-a"
                    src="@/assets/img/business/dec-icon1.png"
                    width="156px"
                    height="154px"
                />
                <img
                    class="dec-icon2 po-a"
                    src="@/assets/img/business/dec-icon3.png"
                    width="156px"
                    height="154px"
                />
                <div class="item1">
                    <div class="item-title">
                        <span>好生活·好车 打造汽车新零售生态</span>
                    </div>
                    <div class="item-text">
                        <p>新能源汽车交易服务整合平台承担B2B和B2C双向职能</p>
                    </div>
                </div>
                <img
                    class="po-a item1-pic"
                    src="@/assets/img/business/car-item1.png"
                    width="820px"
                    height="382px"
                />
            </div>
            <div class="flex mar-t-80 po-r mar-t-132">
                <img
                    class="dec-icon3 po-a"
                    src="@/assets/img/business/dec-icon4.png"
                    width="156px"
                    height="154px"
                />
                <img
                    src="@/assets/img/business/car-item2.png"
                    width="648px"
                    height="471px"
                />
                <div class="item2">
                    <div class="item-title">
                        <span>B2B商家管理平台</span>
                    </div>
                    <div class="item-text">
                        <p>
                            为加盟的合伙人提供车源供应链、市场投放、批售金融、集客能力四项赋能
                        </p>
                    </div>
                </div>
            </div>
            <div class="flex po-r mar-t-62">
                <img
                    class="dec-icon4 po-a"
                    src="@/assets/img/business/dec-icon3.png"
                    width="156px"
                    height="154px"
                />
                <div class="item3">
                    <div class="item-title">
                        <span>B2C用户交易平台</span>
                    </div>
                    <div class="item-text">
                        <p>
                            通过商城的线下交付场景、交易运营、零售金融手段及闭环的支付体系，配合极致产品体验粘性，实现2C端销售
                        </p>
                    </div>
                </div>
                <img
                    src="@/assets/img/business/car-item3.png"
                    width="644px"
                    height="372px"
                />
            </div>
        </div>
    </div>
</template>
<script>
 
export default {
  data(){
      return{
          
         
      }
  },
  components: {
  }
}
</script>
<style lang="scss" scoped>
.mar-t-132 {
    margin-top: 132px;
}
.mar-t-62 {
    margin-top: 62px;
}

.item1-pic {
    right: 0;
}
.item1 {
    z-index: 1;
    margin-right: 40px;
    padding-top: 75px;
    height: 382px;
    .item-text {
        width: 335px;
    }
}
.item2 {
    margin-top: 122px;
    margin-left: 86px;
}
.item3{
    margin-right: 66px;
    padding-top: 114px;
}
.dec-icon1 {
    top: 40px;
    left: -40px;
}
.dec-icon2 {
    bottom: -33px;
    right: -35px;
    z-index: 1;
}
.dec-icon3 {
    bottom: 72px;
    left: -40px;
}
.dec-icon4 {
    bottom: -5px;
    right:-33px;
    z-index: -1;
}
</style>
