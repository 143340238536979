<template>
    <div class="health">
        <div class="content-p">
            <div class="flex po-r">
                <img
                    class="dec-icon1 po-a"
                    src="@/assets/img/business/dec-icon1.png"
                    width="156px"
                    height="154px"
                />
                <img
                    class="dec-icon2 po-a"
                    src="@/assets/img/business/dec-icon3.png"
                    width="156px"
                    height="154px"
                />
                <div class="item1">
                    <div class="item-title">
                        <span>新康养、新生态</span>
                    </div>
                    <div class="item-text">
                        <p>引领国人健康养老观念和生活方式的变革</p>
                        <p>
                            依托平台强大的康养资源整合能力，融合房产、医疗、保险、金融，打造服务于用户的好生活、好康养的升级服务
                        </p>
                    </div>
                </div>
                <img
                    src="@/assets/img/business/health-item1.png"
                    width="580px"
                    height="432px"
                />
            </div>
            <div class="flex mar-t-80 po-r mar-b-100">
                <img
                    class="dec-icon3 po-a"
                    src="@/assets/img/business/dec-icon4.png"
                    width="156px"
                    height="154px"
                />
                <img
                    src="@/assets/img/business/health-item2.png"
                    width="534px"
                    height="456px"
                />
                <div class="item2">
                    <div class="item-title">
                        <span>惠仁康养（从化）国际社区</span>
                    </div>
                    <div class="item-text">
                        <p>
                            依托珠江投资雄厚实力整合珠江人寿丰富资源，引进成熟的CCRC养老模式，打造面向各个年龄阶段的“医、养、商、游、育”一体化康养宜居大城
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
 
export default {
  data(){
      return{
          
         
      }
  },
  components: {
  }
}
</script>
<style lang="scss" scoped>
.mar-b-100{
    margin-bottom: 100px;
}
.item1 {
    z-index: 1;
    margin-right: 40px;
    margin-top: 20px;
    background: #fff;
}
.item2 {
    margin-left: 86px;
    margin-top: 108px;
}
.dec-icon1 {
    left: -78px;
}
.dec-icon2 {
    bottom: 0;
    right: -66px;
    z-index: -1;
}
.dec-icon3 {
    bottom: 20px;
    left: -20px;
}
</style>
